<template>
  <div class="payment mt-4">
    <PaymentStepper :step="2" class="d-flex d-md-none mb-2" />
    <v-container fluid class="pt-0">
      <v-row no-gutters>
        <v-col
          class="summary-cards d-flex flex-column mt-4 mt-sm-0"
          cols="12"
          md="3"
          order="0"
          order-md="1"
        >
          <div class="filter-sticky-column">
            <CartInfoAddressCard
              class="mb-2 order-1 order-md-0"
              :shippingAddress="cart.shippingAddress"
              :editable="false"
              @edit="openAddressSelector"
              :isCheckout="false"
              :small="true"
            />

            <CartInfoTimeslotCard
              class="mb-2 order-2 order-md-1"
              :shippingAddress="cart.shippingAddress"
              :timeslot="cart.timeslot"
              :editable="false"
              @edit="openTimeslotSelector"
              :isCheckout="false"
              :small="true"
            />

            <CheckoutSummary
              class="order-0 order-md-2 mb-6"
              :orderCart="cart"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          order="1"
          order-md="0"
          md="9"
          class="cart-info pa-0 pa-sm-3 pr-sm-9"
        >
          <PaymentStepper :step="2" class="d-none d-md-flex" />

          <PaymentInvoice
            @billingFormCompleted="billingFormCompleted"
            class="mt-4"
          />

          <PaymentTypeList
            :options="{
              showRememberCard: false,
              showPaymentMethodImage: true,
              showCardLogos: true,
              orderAmount: cart.totalPrice,
              showPrebilledAmountInfoText: true,
              showPrebilledAmountHtml: true,
              confirm: { color: 'secondary' },
              back: {
                class: 'back-btn'
              },
              titleStyle: 'grey--text text--darken-2 text-body-1 mb-2'
            }"
            :isPaymentDisabled="!billingFormCompletedInternal"
            :paymentNameToPaymentInfo="true"
          />
        </v-col>
      </v-row>
    </v-container>
    <!-- <GiftListSlider
      :timeslotId="cart.timeslot.date + cart.timeslot.timeslotId"
      :userId="cart.user.userId"
      :warehouseId="cart.warehouse.warehouseId"
      :layout="36"
      :limit="24"
      :lg="3"
      :xl="4"
      :paginationClass="'swiper-pagination-linea-220'"
      :showArrows="!$vuetify.breakpoint.xsOnly"
      :showBullets="$vuetify.breakpoint.xsOnly"
    >
      <h2 class="my-3 font-weight-regular texSt-uppercase">
        {{ $t("product.rewards.title") }}
      </h2>
    </GiftListSlider> -->
  </div>
</template>
<style scoped lang="scss">
.payment {
  p {
    margin-top: 9px;
    font-size: 12px;
  }
  .privacy {
    font-size: 14px;
    color: $text-color;
    font-weight: bold;
  }
  .conditions {
    .v-input__slot {
      span {
        color: $text-color;
        a {
          text-decoration: none;
        }
      }
    }
  }
  .v-form {
    .v-text-field {
      max-width: 240px;
    }
  }
  .payment-methods {
    background-color: transparent !important;
    :deep(.v-expansion-panel) {
      background-color: transparent !important;
      padding: 0;
      border-bottom: none;
      border-top: none;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      &::before {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }
      .v-expansion-panel-header {
        background-color: $white;
        margin-bottom: 5px;
        border-bottom: none;
        border-top: none;
        label {
          display: flex;
          align-items: center;
          &::before,
          &::after {
            margin-top: 13px !important;
          }
        }
        .payment-method-img {
          height: 50px !important;
        }
      }
      .v-expansion-panel-content__wrap {
        //background-color: $white;
        padding: 12px 24px !important;

        .prebilled-amount {
          margin-bottom: 20px;
        }
        .caption {
          margin-top: 20px;
        }
      }

      .prebilledAmountInfo {
        margin-bottom: 20px;
        .text {
          color: var(--v-primary-lighten1);
        }
        .sub {
          font-size: 13px;
        }
      }
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-form {
      width: 100%;
    }
  }
}
// .payment-btn-wrapper {
//   @media #{map-get($display-breakpoints, 'sm-and-down')} {
//     position: fixed;
//     width: 100%;
//     bottom: 0px;
//     z-index: 9;
//     background: var(--v-primary-base);
//     margin: 0 -12px;
//     padding: 5px;
//   }
// }
</style>
<script>
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import PaymentStepper from "@/components/payment/PaymentStepper.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import PaymentInvoice from "@/components/payment/PaymentInvoice.vue";
import AnalyticsService from "~/service/analyticsService";

import productMixins from "~/mixins/product";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Checkout",
  mixins: [login, productMixins, cartInfo, categoryMixins],
  components: {
    CheckoutSummary,
    PaymentStepper,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    PaymentTypeList,
    PaymentInvoice
  },
  data() {
    return {
      error: null,
      loading: false,
      billingFormCompletedInternal: false,
      emailKey: 1,
      showAlternativesTooltip: false,
      valid: false,
      sellCondition: false
    };
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Checkout",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      return breadCrumbs;
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      user: "cart/getUser"
    })
  },

  methods: {
    ...mapActions({
      lockCart: "cart/lockCart"
    }),
    billingFormCompleted(completed) {
      this.billingFormCompletedInternal = completed;
    },
    async goToPayment() {
      let vm = this;
      vm.loading = true;
      try {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            await vm.lockCart();
            vm.$router.push({
              name: "Payment"
            });
          }
        }
      } catch (err) {
        await this.needTimeslot();
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    }
  },
  mounted() {
    AnalyticsService.beginCheckout(this.cart);
  }
};
</script>
